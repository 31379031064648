
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'

export default defineComponent({
  name: 'Detail Kelas',
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    CKEditor: CKEditor.component,
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      tasks: [] as any,
      showDetailTask: false,
      showModalTask: false,
      showDeleteTask: false,
      formTask: {
        name: '' as string,
        short_description: '' as string,
        description: '' as string,
        started_at: '' as string,
        expired_at: '' as string,
        is_active: false as boolean,
      },
      taskId: 0 as number,
      task: {} as any,
      errorDeadline: false,
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    }
  },
  mounted() {
    this.getMentorTask()
  },
  computed: {
    checkForm() {
      if (
        this.formTask.name &&
        this.formTask.description &&
        this.formTask.started_at &&
        this.formTask.expired_at
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getMentorTask() {
      this.isLoading = true
      await ClassroomModel.getMentorTaskList(this.classroomCode)
        .then(res => {
          this.isLoading = false
          if (res.success) {
            this.tasks = []
            if (res.data.tasks.length > 0) {
              const task = [] as any
              res.data.tasks.forEach((el: any, i: number) => {
                task.push({
                  ...el,
                  no: i + 1,
                })
              })
              this.tasks = task
            }
            // console.log('task', this.tasks)
          }
        })
        .catch(err => {
          this.$router.push('/mentor/classroom')
          this.isLoading = false
        })
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
    },
    openDetailTask(data: any) {
      this.task = data
      this.showDetailTask = true
    },
    closeDetailTask() {
      this.task = {}
      this.showDetailTask = false
    },
    addNewTask() {
      this.taskId = 0
      this.formTask.name = ''
      this.formTask.short_description = ''
      this.formTask.description = ''
      this.formTask.started_at = ''
      this.formTask.expired_at = ''
      this.formTask.is_active = false
      this.showModalTask = true
      this.errorDeadline = false
    },
    updateTask(data: any) {
      this.taskId = data.id
      this.formTask.name = data.name
      this.formTask.short_description = data.short_description
      this.formTask.description = data.description
      this.formTask.started_at = data.started_at
      this.formTask.expired_at = data.expired_at
      this.formTask.is_active = data.is_active
      this.showModalTask = true
      this.errorDeadline = false
    },
    closeModalTask() {
      this.task = {}
      this.taskId = 0
      this.formTask.name = ''
      this.formTask.short_description = ''
      this.formTask.description = ''
      this.formTask.started_at = ''
      this.formTask.expired_at = ''
      this.formTask.is_active = false
      this.showModalTask = false
      this.errorDeadline = false
    },
    async submitTask() {
      if (this.checkForm) {
        this.formTask.started_at = moment(this.formTask.started_at).format(
          'YYYY-MM-DD HH:mm:00'
        )
        this.formTask.expired_at = moment(this.formTask.expired_at).format(
          'YYYY-MM-DD HH:mm:00'
        )
        const start = new Date(this.formTask.started_at)
        const end = new Date(this.formTask.expired_at)
        this.errorDeadline = false
        if (start.getTime() >= end.getTime()) {
          this.errorDeadline = true
          return
        }

        if (this.taskId == 0) {
          await ClassroomModel.createMentorTask(
            this.classroomCode,
            this.formTask
          )
            .then(res => {
              if (res.success) {
                this.closeModalTask()
                this.getMentorTask()
                this.toast.success('Berhasil tambah tugas')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.toast.error(err.response.data.error[0].message)
            })
        } else {
          await ClassroomModel.updateMentorTask(this.taskId, this.formTask)
            .then(res => {
              if (res.success) {
                this.closeModalTask()
                this.getMentorTask()
                this.toast.success('Berhasil update tugas')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.toast.error(err.response.data.error[0].message)
            })
        }
      }
    },
    showModalDeleteTask(data: any) {
      this.task = data
      this.taskId = data.id
      this.showDeleteTask = true
    },
    closeDeleteTask() {
      this.task = {}
      this.taskId = 0
      this.showDeleteTask = false
    },
    async deleteTask() {
      await ClassroomModel.deleteMentorTask(this.taskId)
        .then(res => {
          if (res.success) {
            this.closeDeleteTask()
            this.getMentorTask()
            this.toast.success('Berhasil menghapus tugas')
          } else {
            this.toast.success('Terjadi kesalahan')
            this.closeDeleteTask()
          }
        })
        .catch(err => {
          this.toast.success(err.response.data.errors[0].message)
        })
    },
  },
})
