<template>
  <div class="w-full md:mx-2 rounded">
    <div class="mb-10 bg-white">
      <div
        class="flex justify-between border-b border-gray-300 mb-3 items-center px-2"
      >
        <p class="p-3 font-desain text-[#212121] font-semibold text-lg">
          List Tugas
        </p>
        <button
          class="bg-[#00446F] text-white px-4 py-2 rounded-full font-solusi font-semibold text-xs h-fit-content flex items-center outline-none focus:outline-none"
          @click="addNewTask()"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            class="mr-1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2143 8.95254H9.45238V13.7144C9.45238 14.2383 9.02381 14.6668 8.5 14.6668C7.97619 14.6668 7.54762 14.2383 7.54762 13.7144V8.95254H2.78572C2.26191 8.95254 1.83334 8.52397 1.83334 8.00016C1.83334 7.47635 2.26191 7.04778 2.78572 7.04778H7.54762V2.28588C7.54762 1.76207 7.97619 1.3335 8.5 1.3335C9.02381 1.3335 9.45238 1.76207 9.45238 2.28588V7.04778H14.2143C14.7381 7.04778 15.1667 7.47635 15.1667 8.00016C15.1667 8.52397 14.7381 8.95254 14.2143 8.95254Z"
              fill="currentColor"
            />
          </svg>
          Tambah Tugas
        </button>
      </div>
      <div class="w-full m-auto">
        <DataTable
          :value="tasks"
          :paginator="true"
          :rows="10"
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          class="p-datatable-sm font-solusi text-sm"
          currentPageReportTemplate="Menampilkan {first} hingga {last} dari {totalRecords} tugas"
          responsiveLayout="scroll"
          :loading="isLoading"
          :rowHover="true"
        >
          <template #empty>
            <div
              class="text-center font-semibold text-accent font-solusi text-base"
            >
              Belum ada tugas
            </div>
          </template>
          <template #loading>
            <div class="text-center font-semibold font-solusi text-base">
              Loading data tugas. Please wait.
            </div>
          </template>
          <Column
            field="name"
            header="No."
            headerClass="w-12 text-[#212121]"
            bodyClass="font-solusi font-semibold text-sm text-[#383838]"
          >
            <template #body="{data}"> {{ data.no }}. </template>
          </Column>
          <Column
            field="name"
            header="Nama Tugas"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ data.name }}
            </template>
          </Column>

          <Column
            field="started_at"
            header="Dimulai"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ formatDate(data.started_at) }}
            </template>
          </Column>

          <Column
            field="expired_at"
            header="Deadline"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ formatDate(data.expired_at) }}
            </template>
          </Column>

          <Column
            field="name"
            header="Status"
            headerClass="w-28"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              <span
                v-if="data.is_active"
                class="font-solusi text-xs font-semibold inline-block py-1 px-2 rounded text-green-600 bg-green-200 last:mr-0 mr-1"
              >
                Aktif
              </span>
              <span
                v-else
                class="font-solusi text-xs font-semibold inline-block py-1 px-2 rounded text-red-600 bg-red-200 last:mr-0 mr-1"
              >
                Tidak aktif
              </span>
            </template>
          </Column>

          <Column
            field="name"
            header="Aksi"
            headerClass="w-auto"
            bodyClass="font-solusi font-medium text-sm flex w-auto"
          >
            <template #body="{data}">
              <button
                v-tooltip.top="'Detail Tugas'"
                @click="openDetailTask(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-show mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00009 13.3337C2.97513 13.3337 0.769722 8.5199 0.666682 8.33366C0.586056 8.18866 0.586161 7.812 0.666786 7.66699C0.769911 7.48074 2.97513 2.66699 8.00009 2.66699C13.025 2.66699 15.2304 7.81409 15.3334 8.00033C15.4141 8.14533 15.4141 8.18865 15.3334 8.33366C15.2303 8.51991 13.025 13.3337 8.00009 13.3337ZM1.66677 8.00033C2.2374 8.86532 4.17753 12.3337 8.00009 12.3337C11.8345 12.3337 13.7647 8.86645 14.3335 8.00021C13.7628 7.13522 11.8226 3.66699 8.00009 3.66699C4.16569 3.66699 2.23557 7.13409 1.66677 8.00033ZM8.00009 10.8802C6.41196 10.8802 5.12009 9.58834 5.12009 8.00021C5.12009 6.41208 6.41196 5.12021 8.00009 5.12021C9.58821 5.12021 10.8801 6.41208 10.8801 8.00021C10.8801 9.58834 9.58821 10.8802 8.00009 10.8802ZM8.00009 6.08021C6.94134 6.08021 6.08009 6.94146 6.08009 8.00021C6.08009 9.05896 6.94134 9.92021 8.00009 9.92021C9.05884 9.92021 9.92009 9.05896 9.92009 8.00021C9.92009 6.94146 9.05884 6.08021 8.00009 6.08021Z"
                    fill="currentColor"
                  />
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Detail</span
                >
              </button>
              <button
                v-tooltip.top="'Edit Tugas'"
                @click="updateTask(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-edit mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.79279 3.33325L13.1668 6.70725L6.54135 13.3326C6.35676 13.5172 6.1272 13.6504 5.87536 13.7191L2.4649 14.6493C2.09165 14.751 1.74917 14.4086 1.85096 14.0353L2.78109 10.6249C2.84977 10.373 2.98299 10.1434 3.16757 9.95887L9.79279 3.33325ZM14.5202 1.97998C15.4519 2.91162 15.4519 4.42211 14.5202 5.35375L13.8735 5.99992L10.4995 2.62658L11.1465 1.97998C12.0781 1.04834 13.5886 1.04834 14.5202 1.97998Z"
                    fill="currentColor"
                  />
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Ubah</span
                >
              </button>
              <button
                v-tooltip.top="'Hapus Tugas'"
                @click="showModalDeleteTask(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-delete mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3333 4C14.3333 4.34189 14.076 4.62367 13.7444 4.66218L13.6667 4.66667H13.1035L12.2826 13.0128C12.1903 13.9513 11.4011 14.6667 10.4581 14.6667H5.54196C4.59894 14.6667 3.80974 13.9513 3.71743 13.0128L2.8965 4.66667H2.33334C1.96515 4.66667 1.66667 4.36819 1.66667 4C1.66667 3.63181 1.96515 3.33333 2.33334 3.33333H5.66667C5.66667 2.04467 6.71134 1 8 1C9.28867 1 10.3333 2.04467 10.3333 3.33333H13.6667C14.0349 3.33333 14.3333 3.63181 14.3333 4ZM9.5 6.16667C9.24687 6.16667 9.03768 6.35477 9.00457 6.59882L9 6.66667V11.3333L9.00457 11.4012C9.03768 11.6452 9.24687 11.8333 9.5 11.8333C9.75313 11.8333 9.96233 11.6452 9.99544 11.4012L10 11.3333V6.66667L9.99544 6.59882C9.96233 6.35477 9.75313 6.16667 9.5 6.16667ZM6.5 6.16667C6.24687 6.16667 6.03768 6.35477 6.00457 6.59882L6 6.66667V11.3333L6.00457 11.4012C6.03768 11.6452 6.24687 11.8333 6.5 11.8333C6.75314 11.8333 6.96233 11.6452 6.99544 11.4012L7 11.3333V6.66667L6.99544 6.59882C6.96233 6.35477 6.75314 6.16667 6.5 6.16667ZM8 2.33333C7.44772 2.33333 7 2.78105 7 3.33333H9C9 2.78105 8.55229 2.33333 8 2.33333Z"
                    fill="currentColor"
                  />
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Hapus</span
                >
              </button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showDetailTask"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showDetailTask"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeDetailTask"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <span class="text-xl font-semibold text-black080 font-desain">{{
                task?.name
              }}</span>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeDetailTask()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div class="py-0 px-6 flex-auto overflow-x-hiden overflow-y-auto">
              <div v-html="task?.description"></div>
            </div>
            <div class="flex justify-center p-3">
              <button
                @click="closeDetailTask"
                class="bg-[#00446F] px-10 py-2 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none"
              >
                Kembali
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showModalTask"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showModalTask"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[85vh]"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <h3 class="text-xl font-semibold text-[#212121] font-desain">
                {{ taskId > 0 ? 'Ubah Tugas' : 'Tambah Tugas' }}
              </h3>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalTask()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div class="px-6 py-3 flex-auto overflow-x-hiden overflow-y-auto">
              <div class="mb-3">
                <label
                  for="name"
                  class="font-solusi text-[#212121] block text-sm font-medium mb-1"
                  >Nama tugas</label
                >
                <input
                  class="w-full font-solusi appearance-none border rounded w-full px-3 py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                  type="text"
                  placeholder="Tuliskan nama tugas"
                  v-model="formTask.name"
                />
              </div>
              <div class="mb-3">
                <label
                  for="name"
                  class="font-solusi text-[#212121] block text-sm font-medium mb-1 flex items-center"
                >
                  Deskripsi tugas
                  <buttton
                    class="ml-1 outline-none focus:outline-none"
                    v-tooltip="{
                      content: 'Penulisan Link harus diawali dengan https://',
                      placement: 'right-start',
                    }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 outline-none focus:outline-none"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </buttton>
                </label>
                <CKEditor
                  id="ckeditor"
                  :editor="editor"
                  v-model="formTask.description"
                  :config="editorConfig"
                  class="'w-full font-solusi"
                ></CKEditor>
              </div>
              <div class="mb-3 grid grid-cols-1 md:grid-cols-2 gap-3">
                <div class="col-span-1">
                  <label class="font-solusi text-sm mb-1">
                    Dimulai tugas
                  </label>
                  <v-date-picker
                    v-model="formTask.started_at"
                    mode="datetime"
                    is24hr
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="font-solusi text-sm px-3 py-2 rounded focus:outline-none w-full"
                        :class="
                          errorDeadline ? 'border-2 border-red-500' : 'border'
                        "
                        placeholder="Tanggal"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>
                <div class="col-span-1">
                  <label class="font-solusi text-sm mb-1">
                    Deadline tugas
                  </label>
                  <v-date-picker
                    v-model="formTask.expired_at"
                    mode="datetime"
                    is24hr
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="font-solusi text-sm px-3 py-2 rounded focus:outline-none w-full"
                        :class="
                          errorDeadline ? 'border-2 border-red-500' : 'border'
                        "
                        placeholder="Tanggal"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>
                <div v-if="errorDeadline" class="col-span-2">
                  <p class="text-xs text-[#DE1306] font-solusi font-semibold">
                    Dedline tugas harus lebih besar dari tanggal dimulai tugas
                  </p>
                </div>
              </div>
              <div class="mb-0">
                <label class="inline-flex items-center">
                  <input
                    type="checkbox"
                    class="h-4 w-4"
                    v-model="formTask.is_active"
                  />
                  <span
                    class="ml-2 text-[#212121] font-solusi text-sm cursor-pointer"
                    >Tampil di Member</span
                  >
                </label>
              </div>
            </div>
            <div class="flex justify-center p-3">
              <button
                class="px-16 py-1 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none"
                :class="
                  checkForm
                    ? 'bg-[#00446F] text-white'
                    : 'border border-gray-100 text-[#9E9E9E] cursor-not-allowed'
                "
                @click="submitTask"
                :disabled="!checkForm"
              >
                {{ taskId > 0 ? 'Ubah' : 'Tambah' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showDeleteTask"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showDeleteTask"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeDeleteTask"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-end p-3 pb-0 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeDeleteTask()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div
              class="pt-0 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto"
            >
              <img
                src="/assets/images/mentor/illustration-delete.svg"
                class="w-1/2 m-auto"
                alt=""
              />
              <p
                class="font-desain font-semibold my-3 text-center text-[#212121] text-xl"
              >
                Hapus Tugas
              </p>
              <p
                class="font-solusi font-medium text-base text-center text-[#383838]"
              >
                Yakin Anda ingin menghapus
                <span class="font-solusi font-semibold text-base">{{
                  task?.name
                }}</span
                >?
              </p>
              <div class="flex justify-evenly mt-8">
                <button
                  @click="closeDeleteTask"
                  class="bg-[#00446F] px-9 py-2 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none"
                >
                  Tidak
                </button>
                <button
                  @click="deleteTask"
                  class="px-9 py-2 font-solusi font-semibold rounded-full outline-none focus:outline-none border-dark-blue text-[#00446F]"
                >
                  Ya, saya yakin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'

export default defineComponent({
  name: 'Detail Kelas',
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    CKEditor: CKEditor.component,
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      tasks: [] as any,
      showDetailTask: false,
      showModalTask: false,
      showDeleteTask: false,
      formTask: {
        name: '' as string,
        short_description: '' as string,
        description: '' as string,
        started_at: '' as string,
        expired_at: '' as string,
        is_active: false as boolean,
      },
      taskId: 0 as number,
      task: {} as any,
      errorDeadline: false,
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    }
  },
  mounted() {
    this.getMentorTask()
  },
  computed: {
    checkForm() {
      if (
        this.formTask.name &&
        this.formTask.description &&
        this.formTask.started_at &&
        this.formTask.expired_at
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getMentorTask() {
      this.isLoading = true
      await ClassroomModel.getMentorTaskList(this.classroomCode)
        .then(res => {
          this.isLoading = false
          if (res.success) {
            this.tasks = []
            if (res.data.tasks.length > 0) {
              const task = [] as any
              res.data.tasks.forEach((el: any, i: number) => {
                task.push({
                  ...el,
                  no: i + 1,
                })
              })
              this.tasks = task
            }
            // console.log('task', this.tasks)
          }
        })
        .catch(err => {
          this.$router.push('/mentor/classroom')
          this.isLoading = false
        })
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
    },
    openDetailTask(data: any) {
      this.task = data
      this.showDetailTask = true
    },
    closeDetailTask() {
      this.task = {}
      this.showDetailTask = false
    },
    addNewTask() {
      this.taskId = 0
      this.formTask.name = ''
      this.formTask.short_description = ''
      this.formTask.description = ''
      this.formTask.started_at = ''
      this.formTask.expired_at = ''
      this.formTask.is_active = false
      this.showModalTask = true
      this.errorDeadline = false
    },
    updateTask(data: any) {
      this.taskId = data.id
      this.formTask.name = data.name
      this.formTask.short_description = data.short_description
      this.formTask.description = data.description
      this.formTask.started_at = data.started_at
      this.formTask.expired_at = data.expired_at
      this.formTask.is_active = data.is_active
      this.showModalTask = true
      this.errorDeadline = false
    },
    closeModalTask() {
      this.task = {}
      this.taskId = 0
      this.formTask.name = ''
      this.formTask.short_description = ''
      this.formTask.description = ''
      this.formTask.started_at = ''
      this.formTask.expired_at = ''
      this.formTask.is_active = false
      this.showModalTask = false
      this.errorDeadline = false
    },
    async submitTask() {
      if (this.checkForm) {
        this.formTask.started_at = moment(this.formTask.started_at).format(
          'YYYY-MM-DD HH:mm:00'
        )
        this.formTask.expired_at = moment(this.formTask.expired_at).format(
          'YYYY-MM-DD HH:mm:00'
        )
        const start = new Date(this.formTask.started_at)
        const end = new Date(this.formTask.expired_at)
        this.errorDeadline = false
        if (start.getTime() >= end.getTime()) {
          this.errorDeadline = true
          return
        }

        if (this.taskId == 0) {
          await ClassroomModel.createMentorTask(
            this.classroomCode,
            this.formTask
          )
            .then(res => {
              if (res.success) {
                this.closeModalTask()
                this.getMentorTask()
                this.toast.success('Berhasil tambah tugas')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.toast.error(err.response.data.error[0].message)
            })
        } else {
          await ClassroomModel.updateMentorTask(this.taskId, this.formTask)
            .then(res => {
              if (res.success) {
                this.closeModalTask()
                this.getMentorTask()
                this.toast.success('Berhasil update tugas')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.toast.error(err.response.data.error[0].message)
            })
        }
      }
    },
    showModalDeleteTask(data: any) {
      this.task = data
      this.taskId = data.id
      this.showDeleteTask = true
    },
    closeDeleteTask() {
      this.task = {}
      this.taskId = 0
      this.showDeleteTask = false
    },
    async deleteTask() {
      await ClassroomModel.deleteMentorTask(this.taskId)
        .then(res => {
          if (res.success) {
            this.closeDeleteTask()
            this.getMentorTask()
            this.toast.success('Berhasil menghapus tugas')
          } else {
            this.toast.success('Terjadi kesalahan')
            this.closeDeleteTask()
          }
        })
        .catch(err => {
          this.toast.success(err.response.data.errors[0].message)
        })
    },
  },
})
</script>

<style scoped>
.tab {
  padding: 10px 20px;
}
.border-b-blue {
  border-bottom: 3px solid #009dff;
}
::v-deep(.p-column-title) {
  font-family: 'SolusiSans';
  font-weight: 700;
}
::v-deep(.p-paginator-current) {
  font-family: 'SolusiSans';
  font-weight: 600;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  font-family: 'SolusiSans';
  font-weight: 500;
}

.max-h-85 {
  max-height: 85vh;
}

.h-fit-content {
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
}

.border-dark-blue {
  border: 1px solid #00446f;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  border: 1px solid #009dff;
  color: #009dff;
  font-weight: 600;
  background-color: #fff;
}
/* ::v-deep(.p-paginator .p-paginator-first) {
  color: #009DFF;
}
::v-deep( .p-paginator .p-paginator-prev) {
  color: #009DFF;
}
::v-deep( .p-paginator .p-paginator-next) {
  color: #009DFF;
}
::v-deep(.p-paginator .p-paginator-last) {
  color: #009DFF;
} */
.btn-show {
  color: #212121;
  border: 1px solid #212121;
}
.btn-edit {
  color: #00446f;
  border: 1px solid #00446f;
}
.btn-delete {
  color: #de1306;
  border: 1px solid #de1306;
}
.btn-show:hover,
.btn-edit:hover,
.btn-delete:hover {
  color: #fff;
  transition-duration: 500ms;
}
.btn-show:hover {
  background-color: #212121;
}
.btn-edit:hover {
  background-color: #00446f;
}
.btn-delete:hover {
  background-color: #de1306;
}

::v-deep(.ck-editor .ck-content ul) {
  list-style-type: disc;
  list-style-position: inside;
}
::v-deep(.ck-editor .ck-content ol) {
  list-style-type: decimal;
  list-style-position: inside;
}
::v-deep(.ck-editor .ck-content ul ul, ol ul) {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
::v-deep(.ck-editor .ck-content ol) {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
::v-deep(.ck-editor .ck-content p) {
  margin-top: 0;
  margin-bottom: 8px;
}
::v-deep(.ck-content) {
  font-family: 'SolusiSans';
  min-height: 150px;
  overflow-y: auto;
}
</style>
